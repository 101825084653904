// source: protobuf/filter/condition.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_filter_condition_types_pb = require('../../protobuf/filter/condition_types_pb.js');
goog.object.extend(proto, protobuf_filter_condition_types_pb);
goog.exportSymbol('proto.filter.Condition', null, global);
goog.exportSymbol('proto.filter.GroupCondition', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.filter.GroupCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.filter.GroupCondition.repeatedFields_, null);
};
goog.inherits(proto.filter.GroupCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.filter.GroupCondition.displayName = 'proto.filter.GroupCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.filter.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.filter.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.filter.Condition.displayName = 'proto.filter.Condition';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.filter.GroupCondition.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.filter.GroupCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.filter.GroupCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.filter.GroupCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.filter.GroupCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    or: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.filter.Condition.toObject, includeInstance),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.filter.GroupCondition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.filter.GroupCondition}
 */
proto.filter.GroupCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.filter.GroupCondition;
  return proto.filter.GroupCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.filter.GroupCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.filter.GroupCondition}
 */
proto.filter.GroupCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 2:
      var value = new proto.filter.Condition;
      reader.readMessage(value,proto.filter.Condition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 3:
      var value = new proto.filter.GroupCondition;
      reader.readMessage(value,proto.filter.GroupCondition.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.filter.GroupCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.filter.GroupCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.filter.GroupCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.filter.GroupCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOr();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.filter.Condition.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.filter.GroupCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool or = 1;
 * @return {boolean}
 */
proto.filter.GroupCondition.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.filter.GroupCondition} returns this
 */
proto.filter.GroupCondition.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated Condition conditions = 2;
 * @return {!Array<!proto.filter.Condition>}
 */
proto.filter.GroupCondition.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.filter.Condition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.filter.Condition, 2));
};


/**
 * @param {!Array<!proto.filter.Condition>} value
 * @return {!proto.filter.GroupCondition} returns this
*/
proto.filter.GroupCondition.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.filter.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.filter.Condition}
 */
proto.filter.GroupCondition.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.filter.Condition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.filter.GroupCondition} returns this
 */
proto.filter.GroupCondition.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * repeated GroupCondition groups = 3;
 * @return {!Array<!proto.filter.GroupCondition>}
 */
proto.filter.GroupCondition.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.filter.GroupCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.filter.GroupCondition, 3));
};


/**
 * @param {!Array<!proto.filter.GroupCondition>} value
 * @return {!proto.filter.GroupCondition} returns this
*/
proto.filter.GroupCondition.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.filter.GroupCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.filter.GroupCondition}
 */
proto.filter.GroupCondition.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.filter.GroupCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.filter.GroupCondition} returns this
 */
proto.filter.GroupCondition.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.filter.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.filter.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.filter.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.filter.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    or: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    equal: (f = msg.getEqual()) && protobuf_filter_condition_types_pb.Equal.toObject(includeInstance, f),
    notEqual: (f = msg.getNotEqual()) && protobuf_filter_condition_types_pb.NotEqual.toObject(includeInstance, f),
    higherThan: (f = msg.getHigherThan()) && protobuf_filter_condition_types_pb.HigherThan.toObject(includeInstance, f),
    higherOrEqual: (f = msg.getHigherOrEqual()) && protobuf_filter_condition_types_pb.HigherOrEqual.toObject(includeInstance, f),
    lowerThan: (f = msg.getLowerThan()) && protobuf_filter_condition_types_pb.LowerThan.toObject(includeInstance, f),
    lowerOrEqual: (f = msg.getLowerOrEqual()) && protobuf_filter_condition_types_pb.LowerOrEqual.toObject(includeInstance, f),
    contains: (f = msg.getContains()) && protobuf_filter_condition_types_pb.Contains.toObject(includeInstance, f),
    notContains: (f = msg.getNotContains()) && protobuf_filter_condition_types_pb.NotContains.toObject(includeInstance, f),
    beginsWith: (f = msg.getBeginsWith()) && protobuf_filter_condition_types_pb.BeginsWith.toObject(includeInstance, f),
    notBeginsWith: (f = msg.getNotBeginsWith()) && protobuf_filter_condition_types_pb.NotBeginsWith.toObject(includeInstance, f),
    endsWith: (f = msg.getEndsWith()) && protobuf_filter_condition_types_pb.EndsWith.toObject(includeInstance, f),
    notEndsWith: (f = msg.getNotEndsWith()) && protobuf_filter_condition_types_pb.NotEndsWith.toObject(includeInstance, f),
    pb_in: (f = msg.getIn()) && protobuf_filter_condition_types_pb.In.toObject(includeInstance, f),
    notIn: (f = msg.getNotIn()) && protobuf_filter_condition_types_pb.NotIn.toObject(includeInstance, f),
    isNull: (f = msg.getIsNull()) && protobuf_filter_condition_types_pb.IsNull.toObject(includeInstance, f),
    isNotNull: (f = msg.getIsNotNull()) && protobuf_filter_condition_types_pb.IsNotNull.toObject(includeInstance, f),
    isTrue: (f = msg.getIsTrue()) && protobuf_filter_condition_types_pb.IsTrue.toObject(includeInstance, f),
    isFalse: (f = msg.getIsFalse()) && protobuf_filter_condition_types_pb.IsFalse.toObject(includeInstance, f),
    isEmpty: (f = msg.getIsEmpty()) && protobuf_filter_condition_types_pb.IsEmpty.toObject(includeInstance, f),
    isNotEmpty: (f = msg.getIsNotEmpty()) && protobuf_filter_condition_types_pb.IsNotEmpty.toObject(includeInstance, f),
    between: (f = msg.getBetween()) && protobuf_filter_condition_types_pb.Between.toObject(includeInstance, f),
    notBetween: (f = msg.getNotBetween()) && protobuf_filter_condition_types_pb.NotBetween.toObject(includeInstance, f),
    betweenUnixTimestamp: (f = msg.getBetweenUnixTimestamp()) && protobuf_filter_condition_types_pb.BetweenUnixTimestamp.toObject(includeInstance, f),
    notBetweenUnixTimestamp: (f = msg.getNotBetweenUnixTimestamp()) && protobuf_filter_condition_types_pb.NotBetweenUnixTimestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.filter.Condition}
 */
proto.filter.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.filter.Condition;
  return proto.filter.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.filter.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.filter.Condition}
 */
proto.filter.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 2:
      var value = new protobuf_filter_condition_types_pb.Equal;
      reader.readMessage(value,protobuf_filter_condition_types_pb.Equal.deserializeBinaryFromReader);
      msg.setEqual(value);
      break;
    case 3:
      var value = new protobuf_filter_condition_types_pb.NotEqual;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotEqual.deserializeBinaryFromReader);
      msg.setNotEqual(value);
      break;
    case 4:
      var value = new protobuf_filter_condition_types_pb.HigherThan;
      reader.readMessage(value,protobuf_filter_condition_types_pb.HigherThan.deserializeBinaryFromReader);
      msg.setHigherThan(value);
      break;
    case 5:
      var value = new protobuf_filter_condition_types_pb.HigherOrEqual;
      reader.readMessage(value,protobuf_filter_condition_types_pb.HigherOrEqual.deserializeBinaryFromReader);
      msg.setHigherOrEqual(value);
      break;
    case 6:
      var value = new protobuf_filter_condition_types_pb.LowerThan;
      reader.readMessage(value,protobuf_filter_condition_types_pb.LowerThan.deserializeBinaryFromReader);
      msg.setLowerThan(value);
      break;
    case 7:
      var value = new protobuf_filter_condition_types_pb.LowerOrEqual;
      reader.readMessage(value,protobuf_filter_condition_types_pb.LowerOrEqual.deserializeBinaryFromReader);
      msg.setLowerOrEqual(value);
      break;
    case 8:
      var value = new protobuf_filter_condition_types_pb.Contains;
      reader.readMessage(value,protobuf_filter_condition_types_pb.Contains.deserializeBinaryFromReader);
      msg.setContains(value);
      break;
    case 9:
      var value = new protobuf_filter_condition_types_pb.NotContains;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotContains.deserializeBinaryFromReader);
      msg.setNotContains(value);
      break;
    case 10:
      var value = new protobuf_filter_condition_types_pb.BeginsWith;
      reader.readMessage(value,protobuf_filter_condition_types_pb.BeginsWith.deserializeBinaryFromReader);
      msg.setBeginsWith(value);
      break;
    case 11:
      var value = new protobuf_filter_condition_types_pb.NotBeginsWith;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotBeginsWith.deserializeBinaryFromReader);
      msg.setNotBeginsWith(value);
      break;
    case 12:
      var value = new protobuf_filter_condition_types_pb.EndsWith;
      reader.readMessage(value,protobuf_filter_condition_types_pb.EndsWith.deserializeBinaryFromReader);
      msg.setEndsWith(value);
      break;
    case 13:
      var value = new protobuf_filter_condition_types_pb.NotEndsWith;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotEndsWith.deserializeBinaryFromReader);
      msg.setNotEndsWith(value);
      break;
    case 14:
      var value = new protobuf_filter_condition_types_pb.In;
      reader.readMessage(value,protobuf_filter_condition_types_pb.In.deserializeBinaryFromReader);
      msg.setIn(value);
      break;
    case 15:
      var value = new protobuf_filter_condition_types_pb.NotIn;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotIn.deserializeBinaryFromReader);
      msg.setNotIn(value);
      break;
    case 16:
      var value = new protobuf_filter_condition_types_pb.IsNull;
      reader.readMessage(value,protobuf_filter_condition_types_pb.IsNull.deserializeBinaryFromReader);
      msg.setIsNull(value);
      break;
    case 17:
      var value = new protobuf_filter_condition_types_pb.IsNotNull;
      reader.readMessage(value,protobuf_filter_condition_types_pb.IsNotNull.deserializeBinaryFromReader);
      msg.setIsNotNull(value);
      break;
    case 18:
      var value = new protobuf_filter_condition_types_pb.IsTrue;
      reader.readMessage(value,protobuf_filter_condition_types_pb.IsTrue.deserializeBinaryFromReader);
      msg.setIsTrue(value);
      break;
    case 19:
      var value = new protobuf_filter_condition_types_pb.IsFalse;
      reader.readMessage(value,protobuf_filter_condition_types_pb.IsFalse.deserializeBinaryFromReader);
      msg.setIsFalse(value);
      break;
    case 20:
      var value = new protobuf_filter_condition_types_pb.IsEmpty;
      reader.readMessage(value,protobuf_filter_condition_types_pb.IsEmpty.deserializeBinaryFromReader);
      msg.setIsEmpty(value);
      break;
    case 21:
      var value = new protobuf_filter_condition_types_pb.IsNotEmpty;
      reader.readMessage(value,protobuf_filter_condition_types_pb.IsNotEmpty.deserializeBinaryFromReader);
      msg.setIsNotEmpty(value);
      break;
    case 22:
      var value = new protobuf_filter_condition_types_pb.Between;
      reader.readMessage(value,protobuf_filter_condition_types_pb.Between.deserializeBinaryFromReader);
      msg.setBetween(value);
      break;
    case 23:
      var value = new protobuf_filter_condition_types_pb.NotBetween;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotBetween.deserializeBinaryFromReader);
      msg.setNotBetween(value);
      break;
    case 24:
      var value = new protobuf_filter_condition_types_pb.BetweenUnixTimestamp;
      reader.readMessage(value,protobuf_filter_condition_types_pb.BetweenUnixTimestamp.deserializeBinaryFromReader);
      msg.setBetweenUnixTimestamp(value);
      break;
    case 25:
      var value = new protobuf_filter_condition_types_pb.NotBetweenUnixTimestamp;
      reader.readMessage(value,protobuf_filter_condition_types_pb.NotBetweenUnixTimestamp.deserializeBinaryFromReader);
      msg.setNotBetweenUnixTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.filter.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.filter.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.filter.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.filter.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOr();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getEqual();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      protobuf_filter_condition_types_pb.Equal.serializeBinaryToWriter
    );
  }
  f = message.getNotEqual();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      protobuf_filter_condition_types_pb.NotEqual.serializeBinaryToWriter
    );
  }
  f = message.getHigherThan();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      protobuf_filter_condition_types_pb.HigherThan.serializeBinaryToWriter
    );
  }
  f = message.getHigherOrEqual();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      protobuf_filter_condition_types_pb.HigherOrEqual.serializeBinaryToWriter
    );
  }
  f = message.getLowerThan();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      protobuf_filter_condition_types_pb.LowerThan.serializeBinaryToWriter
    );
  }
  f = message.getLowerOrEqual();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      protobuf_filter_condition_types_pb.LowerOrEqual.serializeBinaryToWriter
    );
  }
  f = message.getContains();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      protobuf_filter_condition_types_pb.Contains.serializeBinaryToWriter
    );
  }
  f = message.getNotContains();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      protobuf_filter_condition_types_pb.NotContains.serializeBinaryToWriter
    );
  }
  f = message.getBeginsWith();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      protobuf_filter_condition_types_pb.BeginsWith.serializeBinaryToWriter
    );
  }
  f = message.getNotBeginsWith();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      protobuf_filter_condition_types_pb.NotBeginsWith.serializeBinaryToWriter
    );
  }
  f = message.getEndsWith();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      protobuf_filter_condition_types_pb.EndsWith.serializeBinaryToWriter
    );
  }
  f = message.getNotEndsWith();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      protobuf_filter_condition_types_pb.NotEndsWith.serializeBinaryToWriter
    );
  }
  f = message.getIn();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      protobuf_filter_condition_types_pb.In.serializeBinaryToWriter
    );
  }
  f = message.getNotIn();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      protobuf_filter_condition_types_pb.NotIn.serializeBinaryToWriter
    );
  }
  f = message.getIsNull();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      protobuf_filter_condition_types_pb.IsNull.serializeBinaryToWriter
    );
  }
  f = message.getIsNotNull();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      protobuf_filter_condition_types_pb.IsNotNull.serializeBinaryToWriter
    );
  }
  f = message.getIsTrue();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      protobuf_filter_condition_types_pb.IsTrue.serializeBinaryToWriter
    );
  }
  f = message.getIsFalse();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      protobuf_filter_condition_types_pb.IsFalse.serializeBinaryToWriter
    );
  }
  f = message.getIsEmpty();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      protobuf_filter_condition_types_pb.IsEmpty.serializeBinaryToWriter
    );
  }
  f = message.getIsNotEmpty();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      protobuf_filter_condition_types_pb.IsNotEmpty.serializeBinaryToWriter
    );
  }
  f = message.getBetween();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      protobuf_filter_condition_types_pb.Between.serializeBinaryToWriter
    );
  }
  f = message.getNotBetween();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      protobuf_filter_condition_types_pb.NotBetween.serializeBinaryToWriter
    );
  }
  f = message.getBetweenUnixTimestamp();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      protobuf_filter_condition_types_pb.BetweenUnixTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotBetweenUnixTimestamp();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      protobuf_filter_condition_types_pb.NotBetweenUnixTimestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool or = 1;
 * @return {boolean}
 */
proto.filter.Condition.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Equal equal = 2;
 * @return {?proto.filter.Equal}
 */
proto.filter.Condition.prototype.getEqual = function() {
  return /** @type{?proto.filter.Equal} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.Equal, 2));
};


/**
 * @param {?proto.filter.Equal|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setEqual = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearEqual = function() {
  return this.setEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasEqual = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NotEqual not_equal = 3;
 * @return {?proto.filter.NotEqual}
 */
proto.filter.Condition.prototype.getNotEqual = function() {
  return /** @type{?proto.filter.NotEqual} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotEqual, 3));
};


/**
 * @param {?proto.filter.NotEqual|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotEqual = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotEqual = function() {
  return this.setNotEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotEqual = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HigherThan higher_than = 4;
 * @return {?proto.filter.HigherThan}
 */
proto.filter.Condition.prototype.getHigherThan = function() {
  return /** @type{?proto.filter.HigherThan} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.HigherThan, 4));
};


/**
 * @param {?proto.filter.HigherThan|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setHigherThan = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearHigherThan = function() {
  return this.setHigherThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasHigherThan = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HigherOrEqual higher_or_equal = 5;
 * @return {?proto.filter.HigherOrEqual}
 */
proto.filter.Condition.prototype.getHigherOrEqual = function() {
  return /** @type{?proto.filter.HigherOrEqual} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.HigherOrEqual, 5));
};


/**
 * @param {?proto.filter.HigherOrEqual|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setHigherOrEqual = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearHigherOrEqual = function() {
  return this.setHigherOrEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasHigherOrEqual = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LowerThan lower_than = 6;
 * @return {?proto.filter.LowerThan}
 */
proto.filter.Condition.prototype.getLowerThan = function() {
  return /** @type{?proto.filter.LowerThan} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.LowerThan, 6));
};


/**
 * @param {?proto.filter.LowerThan|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setLowerThan = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearLowerThan = function() {
  return this.setLowerThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasLowerThan = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LowerOrEqual lower_or_equal = 7;
 * @return {?proto.filter.LowerOrEqual}
 */
proto.filter.Condition.prototype.getLowerOrEqual = function() {
  return /** @type{?proto.filter.LowerOrEqual} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.LowerOrEqual, 7));
};


/**
 * @param {?proto.filter.LowerOrEqual|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setLowerOrEqual = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearLowerOrEqual = function() {
  return this.setLowerOrEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasLowerOrEqual = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Contains contains = 8;
 * @return {?proto.filter.Contains}
 */
proto.filter.Condition.prototype.getContains = function() {
  return /** @type{?proto.filter.Contains} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.Contains, 8));
};


/**
 * @param {?proto.filter.Contains|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setContains = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearContains = function() {
  return this.setContains(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasContains = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NotContains not_contains = 9;
 * @return {?proto.filter.NotContains}
 */
proto.filter.Condition.prototype.getNotContains = function() {
  return /** @type{?proto.filter.NotContains} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotContains, 9));
};


/**
 * @param {?proto.filter.NotContains|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotContains = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotContains = function() {
  return this.setNotContains(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotContains = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional BeginsWith begins_with = 10;
 * @return {?proto.filter.BeginsWith}
 */
proto.filter.Condition.prototype.getBeginsWith = function() {
  return /** @type{?proto.filter.BeginsWith} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.BeginsWith, 10));
};


/**
 * @param {?proto.filter.BeginsWith|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setBeginsWith = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearBeginsWith = function() {
  return this.setBeginsWith(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasBeginsWith = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NotBeginsWith not_begins_with = 11;
 * @return {?proto.filter.NotBeginsWith}
 */
proto.filter.Condition.prototype.getNotBeginsWith = function() {
  return /** @type{?proto.filter.NotBeginsWith} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotBeginsWith, 11));
};


/**
 * @param {?proto.filter.NotBeginsWith|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotBeginsWith = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotBeginsWith = function() {
  return this.setNotBeginsWith(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotBeginsWith = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional EndsWith ends_with = 12;
 * @return {?proto.filter.EndsWith}
 */
proto.filter.Condition.prototype.getEndsWith = function() {
  return /** @type{?proto.filter.EndsWith} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.EndsWith, 12));
};


/**
 * @param {?proto.filter.EndsWith|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setEndsWith = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearEndsWith = function() {
  return this.setEndsWith(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasEndsWith = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NotEndsWith not_ends_with = 13;
 * @return {?proto.filter.NotEndsWith}
 */
proto.filter.Condition.prototype.getNotEndsWith = function() {
  return /** @type{?proto.filter.NotEndsWith} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotEndsWith, 13));
};


/**
 * @param {?proto.filter.NotEndsWith|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotEndsWith = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotEndsWith = function() {
  return this.setNotEndsWith(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotEndsWith = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional In in = 14;
 * @return {?proto.filter.In}
 */
proto.filter.Condition.prototype.getIn = function() {
  return /** @type{?proto.filter.In} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.In, 14));
};


/**
 * @param {?proto.filter.In|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIn = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIn = function() {
  return this.setIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIn = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NotIn not_in = 15;
 * @return {?proto.filter.NotIn}
 */
proto.filter.Condition.prototype.getNotIn = function() {
  return /** @type{?proto.filter.NotIn} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotIn, 15));
};


/**
 * @param {?proto.filter.NotIn|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotIn = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotIn = function() {
  return this.setNotIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotIn = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional IsNull is_null = 16;
 * @return {?proto.filter.IsNull}
 */
proto.filter.Condition.prototype.getIsNull = function() {
  return /** @type{?proto.filter.IsNull} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.IsNull, 16));
};


/**
 * @param {?proto.filter.IsNull|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIsNull = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIsNull = function() {
  return this.setIsNull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIsNull = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional IsNotNull is_not_null = 17;
 * @return {?proto.filter.IsNotNull}
 */
proto.filter.Condition.prototype.getIsNotNull = function() {
  return /** @type{?proto.filter.IsNotNull} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.IsNotNull, 17));
};


/**
 * @param {?proto.filter.IsNotNull|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIsNotNull = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIsNotNull = function() {
  return this.setIsNotNull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIsNotNull = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional IsTrue is_true = 18;
 * @return {?proto.filter.IsTrue}
 */
proto.filter.Condition.prototype.getIsTrue = function() {
  return /** @type{?proto.filter.IsTrue} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.IsTrue, 18));
};


/**
 * @param {?proto.filter.IsTrue|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIsTrue = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIsTrue = function() {
  return this.setIsTrue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIsTrue = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional IsFalse is_false = 19;
 * @return {?proto.filter.IsFalse}
 */
proto.filter.Condition.prototype.getIsFalse = function() {
  return /** @type{?proto.filter.IsFalse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.IsFalse, 19));
};


/**
 * @param {?proto.filter.IsFalse|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIsFalse = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIsFalse = function() {
  return this.setIsFalse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIsFalse = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional IsEmpty is_empty = 20;
 * @return {?proto.filter.IsEmpty}
 */
proto.filter.Condition.prototype.getIsEmpty = function() {
  return /** @type{?proto.filter.IsEmpty} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.IsEmpty, 20));
};


/**
 * @param {?proto.filter.IsEmpty|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIsEmpty = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIsEmpty = function() {
  return this.setIsEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIsEmpty = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional IsNotEmpty is_not_empty = 21;
 * @return {?proto.filter.IsNotEmpty}
 */
proto.filter.Condition.prototype.getIsNotEmpty = function() {
  return /** @type{?proto.filter.IsNotEmpty} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.IsNotEmpty, 21));
};


/**
 * @param {?proto.filter.IsNotEmpty|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setIsNotEmpty = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearIsNotEmpty = function() {
  return this.setIsNotEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasIsNotEmpty = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Between between = 22;
 * @return {?proto.filter.Between}
 */
proto.filter.Condition.prototype.getBetween = function() {
  return /** @type{?proto.filter.Between} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.Between, 22));
};


/**
 * @param {?proto.filter.Between|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setBetween = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearBetween = function() {
  return this.setBetween(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasBetween = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional NotBetween not_between = 23;
 * @return {?proto.filter.NotBetween}
 */
proto.filter.Condition.prototype.getNotBetween = function() {
  return /** @type{?proto.filter.NotBetween} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotBetween, 23));
};


/**
 * @param {?proto.filter.NotBetween|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotBetween = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotBetween = function() {
  return this.setNotBetween(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotBetween = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional BetweenUnixTimestamp between_unix_timestamp = 24;
 * @return {?proto.filter.BetweenUnixTimestamp}
 */
proto.filter.Condition.prototype.getBetweenUnixTimestamp = function() {
  return /** @type{?proto.filter.BetweenUnixTimestamp} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.BetweenUnixTimestamp, 24));
};


/**
 * @param {?proto.filter.BetweenUnixTimestamp|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setBetweenUnixTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearBetweenUnixTimestamp = function() {
  return this.setBetweenUnixTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasBetweenUnixTimestamp = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NotBetweenUnixTimestamp not_between_unix_timestamp = 25;
 * @return {?proto.filter.NotBetweenUnixTimestamp}
 */
proto.filter.Condition.prototype.getNotBetweenUnixTimestamp = function() {
  return /** @type{?proto.filter.NotBetweenUnixTimestamp} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_condition_types_pb.NotBetweenUnixTimestamp, 25));
};


/**
 * @param {?proto.filter.NotBetweenUnixTimestamp|undefined} value
 * @return {!proto.filter.Condition} returns this
*/
proto.filter.Condition.prototype.setNotBetweenUnixTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.filter.Condition} returns this
 */
proto.filter.Condition.prototype.clearNotBetweenUnixTimestamp = function() {
  return this.setNotBetweenUnixTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.filter.Condition.prototype.hasNotBetweenUnixTimestamp = function() {
  return jspb.Message.getField(this, 25) != null;
};


goog.object.extend(exports, proto.filter);
